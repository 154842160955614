import gql from 'graphql-tag'

const GuiaModel = {
    create: (client, {
        periodo, operacion_id, subdiario_id, glosa, glosa_referencia, comprobante_id,
        fecha_emision, fecha_vencimiento, fecha_contable, moneda_id, tipo_cambio, dato_estructurado,
        tipo_operacion_kardex, tipo_cdp_regimen_sunat, serie_cdp_regimen_sunat, correlativo_cdp_regimen_sunat,
        importe_cdp_regimen_sunat, persona_asociado_id, movimiento, guia, codigo_libro, json
    }, recursos) => client.mutate({
        mutation: gql`mutation createOperacionGuia(
                        $periodo: String,
                        $operacion_id: String,
                        $subdiario_id: String,
                        $glosa: String,
                        $glosa_referencia: String,
                        $comprobante_id: String,
                        $fecha_emision: String,
                        $fecha_vencimiento: String,
                        $fecha_contable: String,
                        $moneda_id: String,
                        $tipo_cambio: Float, 
                        $dato_estructurado: String, 
                        $tipo_operacion_kardex: String, 
                        $tipo_cdp_regimen_sunat: String,
                        $serie_cdp_regimen_sunat: String, 
                        $correlativo_cdp_regimen_sunat: String, 
                        $importe_cdp_regimen_sunat: Float,
                        $persona_asociado_id: String,
                        $movimiento: JSON,
                        $guia: JSON,
                        $codigo_libro: String,
                        $json: JSON
                     ) {
                        createOperacionGuia(
                        periodo: $periodo,
                        operacion_id: $operacion_id,
                        subdiario_id: $subdiario_id,
                        glosa: $glosa,
                        glosa_referencia: $glosa_referencia,
                        comprobante_id: $comprobante_id,
                        fecha_emision: $fecha_emision,
                        fecha_vencimiento: $fecha_vencimiento,
                        fecha_contable: $fecha_contable,
                        moneda_id: $moneda_id,
                        tipo_cambio: $tipo_cambio,
                        dato_estructurado: $dato_estructurado,
                        tipo_operacion_kardex: $tipo_operacion_kardex,
                        tipo_cdp_regimen_sunat: $tipo_cdp_regimen_sunat,
                        serie_cdp_regimen_sunat: $serie_cdp_regimen_sunat,
                        correlativo_cdp_regimen_sunat: $correlativo_cdp_regimen_sunat,
                        importe_cdp_regimen_sunat: $importe_cdp_regimen_sunat,
                        persona_asociado_id: $persona_asociado_id,
                        movimiento: $movimiento,
                        guia: $guia,
                        codigo_libro: $codigo_libro, 
                        json: $json
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            periodo, operacion_id, subdiario_id, glosa, glosa_referencia, comprobante_id,
            fecha_emision, fecha_vencimiento, fecha_contable, moneda_id, tipo_cambio, dato_estructurado,
            tipo_operacion_kardex, tipo_cdp_regimen_sunat, serie_cdp_regimen_sunat, correlativo_cdp_regimen_sunat,
            importe_cdp_regimen_sunat, persona_asociado_id, movimiento, guia, codigo_libro, json
        }
    }),
    createIndividual: (client, {
        comprobante_id, fecha_emision, moneda_id, tipo_cambio, tipo_operacion_kardex,
        persona_asociado_id, movimiento, operacion_facturacion, guia, codigo_libro, json
    }, recursos) => client.mutate({
        mutation: gql`mutation createGuiaIndividual(
                        $comprobante_id: String, 
                        $fecha_emision: String,
                        $moneda_id: String,
                        $tipo_cambio: Float, 
                        $tipo_operacion_kardex: String, 
                        $persona_asociado_id: String,
                        $operacion_facturacion: String,
                        $movimiento: JSON,
                        $guia: JSON,
                        $codigo_libro: String,
                        $json: JSON
                     ) {
                        createGuiaIndividual(
                        comprobante_id: $comprobante_id,
                        fecha_emision: $fecha_emision,
                        moneda_id: $moneda_id,
                        tipo_cambio: $tipo_cambio,
                        tipo_operacion_kardex: $tipo_operacion_kardex,
                        persona_asociado_id: $persona_asociado_id,
                        movimiento: $movimiento,
                        operacion_facturacion: $operacion_facturacion,
                        guia: $guia,
                        codigo_libro: $codigo_libro,
                        json: $json
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            comprobante_id, fecha_emision, moneda_id, tipo_cambio, tipo_operacion_kardex, persona_asociado_id,
            movimiento, guia, codigo_libro, json, operacion_facturacion
        }
    }),
    consultCDR: (client, {external_id, id_guia, onlyTicket = false}) => client.mutate({
        mutation: gql`mutation consultarCDRGuia($external_id: String, $id_guia: String, $onlyTicket: Boolean){
                            consultarCDRGuia(external_id: $external_id, id_guia: $id_guia, onlyTicket: $onlyTicket){
                            result         
               }
        }`,
        variables: {
            external_id, id_guia, onlyTicket
        }
    }),
    recontruirGuia: (client, operacion_id) => client.mutate({
        mutation: gql`mutation reconstruirGuia($operacion_id: String){
                   reconstruirGuia(operacion_id: $operacion_id){
                        result   
                } 
        }`,
        variables: {operacion_id}
    }),
    consultarEstadoSunat: (client, data, recursos) => client.query({
        query: gql`query getEstadoGuiaSunat($data: JSON) {
            getEstadoGuiaSunat(data: $data) {
                ${recursos}
            }
        }`,
        variables: {data},
        fetchPolicy: 'no-cache'
    }),
    BajaGuiaSunat: (client, data, recursos) => client.query({
        query: gql`query getBajaGuiaSunat($data: JSON) {
            getBajaGuiaSunat(data: $data) {
                ${recursos}
            }
        }`,
        variables: {data},
        fetchPolicy: 'no-cache'
    }),
}
export default GuiaModel
